import React from "react";
import { Translation } from 'react-i18next';

/* Select CartItem layout */
import CartItem from "../CartItem/NewCartItem";
// import CartItem from "../CartItem/CartItem";

export default class CurrentCart extends React.Component {
    render() {
        const { cart } = this.props;
        return (
            <Translation>
                {t =>
                    <div className="cart-container">
                        <div className="cart-menu-title">{t("currentCart.yourCart")}</div>
                        {/* <div className="cart-heading d-none d-sm-block mb-2">
                            <div className="row no-gutters">
                                <div style={{ width: "65px" }}>{t("products")}</div>
                                <div className="col pl-0 pr-3">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-4 text-left px-0" />
                                            <div className="col-2 text-right px-0">
                                                {t("currentCart.price")}
                                            </div>
                                            <div className="col-1"></div>
                                            <div className="col-2 text-center">{t("amount")}</div>
                                            <div className="col-2 text-right text-sm-center px-0">{t("total")}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="cart-item-scrollable">
                            {cart && cart.items && cart.items.map((item, index) => (
                                <div key={item.id}>
                                    <CartItem key={item.id} item={item} />
                                    {cart.items.length !== index + 1 &&
                                        // <div className="d-block d-sm-none">
                                            <hr />
                                        // </div>
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}