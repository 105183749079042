const config = {
    baseUrl: process.env.REACT_APP_BASE_URL,
    maxSize: 5,
    DEFAULT_STORE_DESCRIPTION : "",
    DEFAULT_STATE: "",
    DEFAULT_STATE_ID: "",
    SHOW_STATE_DROPDOWN : false,
    SHOW_CART_LISTS: false,

    ENABLED_SLIST: false,
    ENABLED_PAYMENTS: false,

    /* Select storeId to deploy */

    // => Ecommerce stage
    // DEFAULT_STORE_ID : "1ed248cf-2ced-4a0b-a0e0-1dfc8d9b6700",

    // => Salumeria Mussetto
    // DEFAULT_STORE_ID : "47ce37de-6af4-4654-97d7-79d81b09fcb8",
    // => Salotto Roma
    DEFAULT_STORE_ID : "fd169880-9b18-416d-b0c4-a9db66c95b0b",
}

export default config;