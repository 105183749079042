import React from "react";
import Placeholder from "../../../images/product-placeholder.png"
import i18n from "../../../i18n"
import { Link } from "react-router-dom";
import { connect } from "react-redux"
import * as actionCreators from "../../../redux/store/actions/index"
import renderPrice from "../../../utils/renderPrice";
import { ReactComponent as DeleteIcon } from '../../../images/delete_icon.svg';

import "./new-cart-item.styles.scss";

class NewCartItem extends React.Component {

    renderPromo = (item) => {
        if (item.promotions) {
            if (item.promotions[0] !== null && item.promotions[0] !== undefined) {
                const label = item.promotions[0].valueType === "UNKNOWN" ? "promo.unknown" : "promo";
                return (
                    <div className="cart-item-promo">
                        {i18n.t(label)}
                    </div>
                );
            } else {
                return (
                    <div className="cart-item-promo">
                        &nbsp;
                    </div>
                );
            }
        }
    }

    renderOptions = (item) => {
        var options = [];
        const maxAmount = item.maxAmount && item.maxAmount <= 10 ? item.maxAmount : 10
        for (var i = 0; i < maxAmount; i++) {
            options.push(i + 1);
        }
        return (
            <>
                {options.map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                ))}
            </>
        );
    }

    renderPrices = (item) => {
        let result;
        if (item.price !== item.netPrice) {
            result =
                <div className="cart-item-prices">
                    <nobr><span className="mr-1 text-strike">
                        {`${renderPrice(item.price.toFixed(2))} \u20AC`}
                    </span>
                    </nobr>
                    <span>
                        {`${renderPrice(item.netPrice.toFixed(2))} \u20AC`}
                    </span>
                </div>
        } else {
            result = <div className="cart-item-prices">
                <span>{`${renderPrice(item.netPrice.toFixed(2))} \u20AC`}</span>
            </div>
        }
        return result
    }

    handleChange = (event) => {
        const { cart, item, store } = this.props;
        store(cart.id, item.barcode, event.target.value, item.um)
    }

    render() {
        const { item, cart, remove } = this.props;
        const link = `/product/${item.barcode}`;
        return (
            <div className="cart-item">
                <div className="cart-item-image">
                    <Link className="link"
                        to={{
                            pathname: link,
                            state: { product: item, isDetail: true }
                        }}>
                        <img src={item.imageUrl ? item.imageUrl : Placeholder} alt=""
                            onError={(e) => { e.target.src = Placeholder }}
                        />
                    </Link>
                </div>
                <div className="cart-item-info">
                    <div className="layout">
                        <div>
                            {this.renderPromo(item)}
                            <Link className="link"
                                to={{
                                    pathname: link,
                                    state: { product: item, isDetail: true }
                                }}>
                                <div className="cart-item-description">{item.description}</div>
                            </Link>
                        </div>
                        <div>
                            <select defaultValue={item.amount} onChange={this.handleChange}>
                                {this.renderOptions(item)}
                            </select>
                        </div>
                    </div>
                    {item.selectedCartItemVariants && item.selectedCartItemVariants.map(variant => (
                        <div className="cart-item-variants">
                            <span className="variant">{variant.variantValue}: </span>
                            <span className="option">
                                {variant.variantOptions && variant.variantOptions[0] && variant.variantOptions[0].optionValue}
                            </span>
                        </div>
                    ))}
                    <div className="action-layout">
                        <div className="cart-item-remove" onClick={() => remove(cart.id, item.barcode)}>
                            <DeleteIcon className="mr-1" />
                            <span>Rimuovi prodotto</span>
                        </div>
                        {this.renderPrices(item)}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        cart: state.cart.cart
    };
}

const mapDispatchToProps = dispatch => {
    return {
        store: (cartId, barcode, amount, um) => dispatch(actionCreators.store(cartId, barcode, amount, um)),
        remove: (cartId, barcode) => dispatch(actionCreators.remove(cartId, barcode)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCartItem);